@import "./_variables.scss";

// Iterate over the $grid-breakpoints map to create media queries
@each $breakpoint, $value in $grid-breakpoints {
  @media (min-width: #{$value}) {
    :root {
      // Use the corresponding value from $content-size for the CSS variable
      --container-max-width: #{map-get($content-size, $breakpoint)};
    }
  }
  .container-max-width-#{$breakpoint} {
    max-width: map-get($content-size, $breakpoint) !important;
  }
}
.container-max-width {
  max-width: var(--container-max-width) !important;
  &-half {
    max-width: calc(var(--container-max-width) / 2) !important;
  }
  &-third {
    max-width: calc(var(--container-max-width) / 3) !important;
  }
  &-quarter {
    max-width: calc(var(--container-max-width) / 4) !important;
  }
}
