$font-size-hero-1: 60px;
$font-size-hero-2: 48px;
$font-size-hero-3: 42px;
$font-size-hero-4: 40px;

$font-size-h1: 30px;
$font-size-h2: 24px;
$font-size-h3: 20px;
$font-size-h4: 19px;
$font-size-h5: 18px;
$font-size-h6: 17px;

$font-size-body: 16px;
$font-size-body-condensed: 14px;
$font-size-footnote: 13px;
$font-size-caption-1: 12px;
$font-size-caption-2: 11px;
$font-size-small-label: 10px;
$font-size-extra-small: 9px;

$line-height-hero-1: 80px;
$line-height-hero-2: 64px;
$line-height-hero-3: 56px;
$line-height-hero-4: 56px;

$line-height-xlarge: 40px;
$line-height-large: 32px;
$line-height-medium: 24px;
$line-height-small: 16px;

$font-weight-extrabold: 800;
$font-weight-bold: 700;
$font-weight-semibold: 600;
$font-weight-medium: 500;
$font-weight-regular: 400;
$font-weight-light: 300;

$font-size-map: (
  "hero-1": (
    "size": $font-size-hero-1,
    "line-height": $line-height-hero-1,
    "spacing": -1.32px,
  ),
  "hero-2": (
    "size": $font-size-hero-2,
    "line-height": $line-height-hero-2,
    "spacing": -1.056px,
  ),
  "hero-3": (
    "size": $font-size-hero-3,
    "line-height": $line-height-hero-3,
    "spacing": -0.924px,
  ),
  "hero-4": (
    "size": $font-size-hero-4,
    "line-height": $line-height-hero-4,
    "spacing": -0.88px,
  ),
  "h1": (
    "size": $font-size-h1,
    "line-height": $line-height-xlarge,
    "spacing": -0.63px,
  ),
  "h2": (
    "size": $font-size-h2,
    "line-height": $line-height-large,
    "spacing": -0.456px,
  ),
  "h3": (
    "size": $font-size-h3,
    "line-height": $line-height-large,
    "spacing": -0.34px,
  ),
  "h4": (
    "size": $font-size-h4,
    "line-height": $line-height-large,
    "spacing": -0.304px,
  ),
  "h5": (
    "size": $font-size-h5,
    "line-height": $line-height-medium,
    "spacing": -0.252px,
  ),
  "h6": (
    "size": $font-size-h6,
    "line-height": $line-height-medium,
    "spacing": -0.221px,
  ),
  "body": (
    "size": $font-size-body,
    "line-height": $line-height-medium,
    "spacing": -0.176px,
  ),
  "body-condensed": (
    "size": $font-size-body-condensed,
    "line-height": $line-height-medium,
    "spacing": -0.084px,
  ),
  "footnote": (
    "size": $font-size-footnote,
    "line-height": $line-height-medium,
    "spacing": -0.032px,
  ),
  "caption-1": (
    "size": $font-size-caption-1,
    "line-height": $line-height-small,
    "spacing": 0,
  ),
  "caption-2": (
    "size": $font-size-caption-2,
    "line-height": $line-height-small,
    "spacing": 0.055px,
  ),
  "small-label": (
    "size": $font-size-small-label,
    "line-height": $line-height-small,
    "spacing": 0.1px,
  ),
  "extra-small": (
    "size": $font-size-extra-small,
    "line-height": $line-height-small,
    "spacing": 0.144px,
  ),
);
