.error-fallback {
  .alert {
    border-radius: 25%;
  }
  i {
    font-size: 5rem;
  }

  &-text {
    max-width: 360px;
  }
}
