@import "../../sass/base";

.error {
  &-image {
    max-width: 600px;
  }

  &-layout {
    padding: $grid-gutter-width $grid-gutter-width / 6;

    @include media-breakpoint-up(lg) {
      padding-top: 100px;
      padding-bottom: 100px;
    }
  }
}
