@import "../../sass/base";

.center-layout {
  $top-padding: 100px;

  background-color: map-get($neutral-color, "10");
  padding: 0;

  &-container {
    padding: 0 0 50px;

    @include media-breakpoint-up(md) {
      padding: $top-padding $grid-gutter-width 50px;
    }
  }

  &-dark {
    background-image: url("../../image/dark-corner-top.svg"),
      url("../../image/dark-corner-bottom.svg"), linear-gradient(#243746 0%, #1a2732 100%);
    background-size: 240px, 240px, cover;
    background-repeat: no-repeat;
    background-position: top left, bottom right, center;

    @include media-breakpoint-up(md) {
      background-size: 480px, 480px, cover;
    }

    .center-layout-footer {
      color: map-get($neutral-color, "00");
    }
  }

  &-header {
    $header-padding: 25px;
    $logo-height: 28px;

    padding: $header-padding;
    &-logo {
      height: $logo-height;
    }
    & + .center-layout-container {
      padding-top: $top-padding - ($header-padding * 2) - $logo-height;
    }
  }

  &-footer {
    gap: $grid-gutter-width / 2;
    padding: 0 $grid-gutter-width $grid-gutter-width;

    @include media-breakpoint-up(md) {
      gap: $grid-gutter-width * 1.5;
    }
    @include media-breakpoint-up(lg) {
      padding: 0 80px $grid-gutter-width;
    }

    &-copyright {
      max-width: 660px;
    }
    &-contact {
      @include get-font-size(body-condensed);
      text-align: left;

      @include media-breakpoint-up(md) {
        text-align: right;
      }
    }
  }
}
