@import "./sass/root";

:root {
  --breakpoint: xs;

  @include media-breakpoint-up(sm) {
    --breakpoint: sm;
  }
  @include media-breakpoint-up(md) {
    --breakpoint: md;
  }
  @include media-breakpoint-up(lg) {
    --breakpoint: lg;
  }
  @include media-breakpoint-up(xl) {
    --breakpoint: xl;
  }
}

html,
body,
#root {
  min-height: 100vh;
}

// This removes the up and down arrows from number inputs
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
body.body-dark {
  background-color: map-get($theme-colors, "secondary");
  color: map-get($neutral-color, "00");
}

#root {
  display: flex;
  flex-direction: column;
}
main {
  flex-grow: 1;
}
header {
  padding: map-get($padding-map, "standard");
}

footer {
  padding: $grid-gutter-width 0px;
}

.btn svg {
  fill: currentColor;
}
.brand-color-bar {
  border: 2px solid map-get($theme-colors, "brand");
  border-radius: 4px;
  width: 120px;
  margin: $grid-gutter-width / 2 0px;
}

.neutral-box {
  background-color: map-get($neutral-color, "05");
  border-radius: 8px;
  padding: $grid-gutter-width;
}

.border-highlight {
  &-primary {
    border-top: 4px solid map-get($primary-color, "50") !important;
  }
  &-secondary {
    border-top: 4px solid map-get($theme-colors, "brand") !important;
  }
  &-tertiary {
    border-top: 4px solid #feb600 !important;
  }
}

svg {
  fill: map-get($neutral-color, "50");
}

#okta-sign-in .js-help {
  display: none;
}
