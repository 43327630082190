@import "../../sass/base";

body.okta {
  #okta-sign-in {
    margin: 0 auto;
  }
  #okta-sign-in .social-auth-general-idp-button {
    background: url("../../../public/img/wex-logo.png") no-repeat 4px/44px,
      linear-gradient(90deg, #fff 50px, #fbfbfb 0);
  }
  #okta-sign-in .social-auth-general-idp-button:active,
  #okta-sign-in .social-auth-general-idp-button:focus,
  #okta-sign-in .social-auth-general-idp-button:hover {
    background: url("../../../public/img/wex-logo.png") no-repeat 4px/44px,
      linear-gradient(90deg, #fff 50px, #fff 0);
  }

  #okta-sign-in .sign-in-with-idp .separation-line {
    text-transform: initial !important;
  }

  #okta-sign-in.auth-container,
  .okta-layout-container .auth-container {
    * {
      font-size: 1rem;
      font-family: "Inter", "Roboto", sans-serif;
    }

    .auth-header {
      display: block;
    }

    .button-primary {
      background: map-get($theme-colors, "primary");
      border: none;
      color: map-get($neutral-color, "00");
    }
  }
}

.okta-layout-container {
  @include media-breakpoint-down(sm) {
    padding: 0;
  }
}

.okta-layout-fallback {
  &-wrapper,
  &-container {
    width: 400px;
  }
  @media only screen and (max-width: 600px) {
    &-wrapper,
    &-container {
      width: 100%;
    }
  }

  &-wrapper {
    position: relative;
    margin: auto;
  }
  &-container {
    position: absolute;
    background-color: map-get($neutral-color, "00");
    box-shadow: 0 2px 0 hsla(0, 0%, 69%, 0.12);
    border: 1px solid #ddd;
    border-color: #ddd #ddd #d8d8d8;

    border-radius: 3px;
  }

  &-header {
    padding: 30px 90px;
    border-bottom: 1px solid #ddd;
    transition: padding-bottom 0.4s;
    min-height: 89px;
    h1 {
      font-size: 1rem;
      font-family: "Inter", "Roboto", sans-serif;
      padding: 0px;
      margin: 0px;
    }
    img {
      display: block;
      margin: 0 auto;
      max-height: 40px;
      max-width: 200px;
      vertical-align: baseline;
    }
  }
  &-content {
    padding: 20px 42px;
    min-height: 533px;
  }
  &-body {
    min-height: 473px;
  }
  &-footer {
    border-top: 1px solid #ddd;
    min-height: 60px;
  }
  .siw-main-body {
    min-height: 342px;
  }
  .siw-main-footer {
    min-height: 100px;
    padding-top: 40px !important;
  }
  .footer-info {
    padding-top: 100px;
  }
}
