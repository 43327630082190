@import "../../sass/base";

.initial-icon {
  line-height: 1 !important;
  width: 1em * 2.22;
  height: 1em * 2.22;
}

.verified-icon {
  color: map-get($info-color, "50");
}
svg.success-icon {
  fill: map-get($success-color, "50");
}
svg.error-icon {
  fill: map-get($critical-color, "50");
}
.encircled {
  border-radius: 50%;
  background-color: map-get($neutral-color, "10");
  align-items: center;
  padding: 0.5em;
  line-height: 0px;
  width: 1em;
  height: 1em;
  &-theme {
    &-primary {
      background-color: map-get($primary-color, "05");
      color: map-get($primary-color, "50");
    }
    &-success {
      background-color: map-get($success-color, "05");
      color: map-get($success-color, "50");
    }
    &-error {
      background-color: map-get($critical-color, "05");
      color: map-get($critical-color, "50");
    }
    &-info {
      background-color: map-get($info-color, "05");
      color: map-get($info-color, "50");
    }
    &-warning {
      background-color: map-get($warning-color, "05");
      color: map-get($warning-color, "50");
    }
  }
}
