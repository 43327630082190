@import "./colors";
@import "./typography-variables";
@import "./variables";

.bg {
  &-clip-text {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
    background-clip: text !important;
    text-fill-color: transparent !important;
  }
  &-dark {
    background-color: map-get($theme-colors, "secondary") !important;
  }
  &-light {
    background-color: map-get($neutral-color, "00") !important;
  }
  &-gray {
    background-color: map-get($neutral-color, "05") !important;
  }
}

.icon {
  &-xs {
    width: $font-size-caption-1;
    height: $font-size-caption-1;
  }

  &-sm {
    width: $font-size-body;
    height: $font-size-body;
  }

  &-md {
    width: $font-size-h3;
    height: $font-size-h3;
  }

  &-lg {
    width: $font-size-h1;
    height: $font-size-h1;
  }
}

.list {
  &-ordered {
    list-style: decimal;
  }
  &-unordered {
    list-style: disc;
  }
  &-ordered,
  &-unordered {
    padding-left: 1.5em;

    li:not(:last-child) {
      margin-bottom: 5px;
    }
  }
}

.button-unstyled {
  border: 0;
  padding: 0;
  background-color: transparent;
}

.text-black {
  color: map-get($neutral-color, "100");
}
.alert-info {
  svg {
    color: map-get($info-color, "50");
  }
  border-color: map-get($info-color, "50");
}

.flat-paper {
  box-shadow: none !important;
}

.min-vh {
  &-50 {
    min-height: 50vh;
  }
  &-75 {
    min-height: 75vh;
  }
  &-100 {
    min-height: 100vh;
  }
}

.max-width {
  &-sm {
    max-width: map-get($content-size, "sm");
  }
  &-md {
    max-width: map-get($content-size, "md");
  }
}

.flex-gap {
  &-0 {
    gap: 0 !important;
  }
  &-1 {
    gap: map-get($spacers, 1) !important;
  }
  &-2 {
    gap: map-get($spacers, 2) !important;
  }
  &-3 {
    gap: map-get($spacers, 3) !important;
  }
  &-4 {
    gap: map-get($spacers, 4) !important;
  }
  &-5 {
    gap: map-get($spacers, 5) !important;
  }
}

.flex-proportional {
  flex: 1;
}

.overflow {
  &-hidden {
    overflow: hidden;
  }
  &-auto {
    overflow: auto;
  }
  &-y {
    &-hidden {
      overflow: hidden;
    }
    &-auto {
      overflow: auto;
    }
  }
  &-x {
    &-hidden {
      overflow: hidden;
    }
    &-auto {
      overflow: auto;
    }
  }
}

.invisible {
  visibility: hidden;
}

.rounded {
  &-md {
    border-radius: map-get($border-radius-map, "md");
  }
  &-lg {
    border-radius: map-get($border-radius-map, "lg");
  }
}

.rounded-border {
  border: 1px solid map-get($neutral-color, "20");
  border-radius: map-get($border-radius-map, "md") !important;

  &-lg {
    border: 1px solid map-get($neutral-color, "20");
    border-radius: map-get($border-radius-map, "lg") !important;
  }
}

.truncate-text {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
