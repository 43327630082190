@import "./typography-variables";

@mixin get-font-size($size, $important: false) {
  $map: map-get($font-size-map, $size);
  $font-size: map-get($map, "size");
  $line-height: map-get($map, "line-height");
  $letter-spacing: map-get($map, "spacing");

  @if $important {
    font-size: $font-size !important;
    line-height: $line-height !important;
    letter-spacing: $letter-spacing !important;
  } @else {
    font-size: $font-size;
    line-height: $line-height;
    letter-spacing: $letter-spacing;
  }
}
