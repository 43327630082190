@import "../../sass/base";

.muted-button,
.muted-button.MuiButton-root {
  display: flex;
  flex-direction: column;
  color: map-get($primary-color, "50");
  background-color: map-get($neutral-color, "05");
  padding: 8px;
  @include get-font-size(body-condensed);

  &:hover {
    color: map-get($primary-color, "50");
    background-color: map-get($neutral-color, "10");
  }

  &.muted-button {
    &-disabled {
      color: map-get($neutral-color, "40");
      background-color: map-get($neutral-color, "10");
    }
  }

  .muted-button {
    &-icon {
      margin: 0;
      @include get-font-size(body-condensed);
    }
  }
}
