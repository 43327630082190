@import "./typography-variables";
@import "./typography-mixins";

html,
body {
  font-family: "Inter", "Roboto", sans-serif;
  @include get-font-size(body);
}

.font-size-hero-1 {
  @include get-font-size(hero-2, true);

  @include media-breakpoint-up(lg) {
    @include get-font-size(hero-1, true);
  }
}
.font-size-hero-2 {
  @include get-font-size(hero-3, true);

  @include media-breakpoint-up(lg) {
    @include get-font-size(hero-2, true);
  }
}
.font-size-hero-3 {
  @include get-font-size(hero-4, true);

  @include media-breakpoint-up(lg) {
    @include get-font-size(hero-3, true);
  }
}
.font-size-hero-4 {
  @include get-font-size(h1, true);

  @include media-breakpoint-up(lg) {
    @include get-font-size(hero-4, true);
  }
}

h1 {
  @include get-font-size(h2);

  @include media-breakpoint-up(lg) {
    @include get-font-size(h1);
  }
}
.font-size-h1 {
  @include get-font-size(h2, true);

  @include media-breakpoint-up(lg) {
    @include get-font-size(h1, true);
  }
}
h2 {
  @include get-font-size(h2);
}
h3 {
  @include get-font-size(h3);
}
h4 {
  @include get-font-size(h4);
}
h5 {
  @include get-font-size(h5);
}
h6 {
  @include get-font-size(h6);
}
.font-size-h2 {
  @include get-font-size(h2, true);
}
.font-size-h3 {
  @include get-font-size(h3, true);
}
.font-size-h4 {
  @include get-font-size(h4, true);
}
.font-size-h5 {
  @include get-font-size(h5, true);
}
.font-size-h6 {
  @include get-font-size(h6, true);
}

.font-size-body {
  @include get-font-size(body, true);
}
.font-size-body-condensed {
  @include get-font-size(body-condensed, true);
}
.font-size-footnote {
  @include get-font-size(footnote, true);
}
.font-size-caption-1 {
  @include get-font-size(caption-1, true);
}
.font-size-caption-2 {
  @include get-font-size(caption-2, true);
}
.font-size-small-label {
  @include get-font-size(small-label, true);
}
.font-size-extra-small {
  @include get-font-size(extra-small, true);
}

.font-all-caps-large {
  @include get-font-size(body, true);
  text-transform: uppercase;
}
.font-all-caps-small {
  @include get-font-size(caption-1, true);
  text-transform: uppercase;
}
.font-all-caps {
  text-transform: uppercase !important;
}
.font-lowercase {
  text-transform: lowercase !important;
}
.font-capitalize {
  text-transform: capitalize !important;
}
.font-transform-none {
  text-transform: none !important;
}

.font-weight-light {
  font-weight: $font-weight-light !important;
}
.font-weight-regular {
  font-weight: $font-weight-regular !important;
}
.font-weight-medium {
  font-weight: $font-weight-medium !important;
}
.font-weight-semibold {
  font-weight: $font-weight-semibold !important;
}
.font-weight-bold {
  font-weight: $font-weight-bold !important;
}
.font-weight-extrabold {
  font-weight: $font-weight-extrabold !important;
}

.overflow-wrap {
  &-anywhere {
    overflow-wrap: anywhere !important;
  }
  &-break-word {
    overflow-wrap: break-word !important;
  }
  &-normal {
    overflow-wrap: normal !important;
  }
}

.text-black {
  color: map-get($neutral-color, "00");
}
