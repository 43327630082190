.MuiTextField-root {
  .Mui-disabled {
    &.MuiInputLabel-root {
      color: map-get($neutral-color, "60");
    }

    &.MuiInputBase-root {
      background-color: rgba(map-get($neutral-color, "50"), 0.05);

      input,
      textarea {
        color: map-get($neutral-color, "100");
        -webkit-text-fill-color: unset;
      }
    }
  }
}

.MuiOutlinedInput-root {
  .MuiAutocomplete-endAdornment {
    padding-right: $grid-gutter-width / 3;
  }
}

.MuiTablePagination-root {
  .MuiTablePagination-displayedRows {
    margin-bottom: 0;
  }
}

.MuiAutocomplete-popper {
  box-shadow: 0px 4px 10px 0px rgba(2, 13, 36, 0.15);
}

.MuiAutocomplete-option.Mui-focused {
  background-color: map-get($neutral-color, "10") !important;
}

.ag-theme-material {
  --ag-material-primary-color: #{map-get($primary-color, "50")};
  --ag-material-accent-color: #{map-get($primary-color, "50")};
  --ag-selected-row-background-color: #{map-get($primary-color, "00")};
  --ag-row-hover-color: #{map-get($neutral-color, "05")};
  --ag-checkbox-indeterminate-color: #{map-get($primary-color, "50")};
  --ag-border-color: #{map-get($neutral-color, "10")};
  --ag-disabled-foreground-color: #{map-get($neutral-color, "30")};
  --ag-secondary-foreground-color: #{map-get($neutral-color, "60")};
}
